<template>
  <div class="main">
    <div class="nav">
      <div class="nav-logo">
        <img src="../assets/logo.png" alt="logo">
      </div>
      <router-link class="router-link" to="/register">
        <view class="reg-btn">
          Register
        </view>
      </router-link>
    </div>
    <div class="content">
      <div class="big_title">
        Continue using the MagicLamp app
      </div>
      <div class="small_title">
        For security reasons, you need to continue using the MagicLamp app. Don't have MagicLamp installed yet? Click to
        download the MagicLamp Trial app.
      </div>
      <div class="no_pc" v-if="device !== 'pc'">
        <div class="download">
          <img src="../assets/down.png" alt=""/>
        </div>
        <router-link to="/ios">
          <div class="iso_down_btn" v-if="device === 'ios'">
            <img src="@/assets/IOS.png" alt=""> Download Now
          </div>
        </router-link>
        <div class="android_down_btn" @click="goApp" v-if="device === 'android'">
          <img src="@/assets/andorid.png" alt=""> Download Now
        </div>
        <div class="web_browsing_btn" @click="goWeb">
          Web Browsing
        </div>
        <div class="tips">
          Latest version number: {{ version }}
        </div>
      </div>
      <div class="pc" v-else>
        <img :src="qrcodeSrc" class="qr" alt="QR Code">
        <div class="scan_tip">Please use your cell phone to scan the code</div>
      </div>
    </div>
  </div>
</template>

<script>
import {AppDownloadLink} from "@/api/account";
import QRCode from 'qrcode'

export default {
  data() {
    return {
      url: '',
      version: '',
      device: '',
      qrcodeSrc: ''
    }
  },
  created() {
    // 判断是否为移动设备
    const isMobile = this.isMobileDevice();
    if (isMobile) {
      if (this.isIOS()) {
        this.device = 'ios'
        console.log("iOS 设备");
        // 在此执行 iOS 相关逻辑
      } else if (this.isAndroid()) {
        this.device = 'android'
        console.log("Android 设备");
        // 在此执行 Android 相关逻辑
      }
    } else {
      this.device = 'pc'
      console.log("非移动设备");
      // 如果是 PC 设备，可以执行 PC 相关逻辑
    }
  },
  mounted() {
    // 生成二维码为图片格式
    QRCode.toDataURL('https://miglamp.com', {
      width: 200,
    }).then(url => {
      this.qrcodeSrc = url; // 将生成的二维码图片 URL 保存到 data
    }).catch(error => {
      console.error(error);
    });
    this.getApp()
  },
  methods: {
    getApp() {
      this.$Spin.show();
      AppDownloadLink().then(res => {
        this.url = res.url;
        this.version = res.version;
        this.$Spin.hide();
        //console.log(res)
      })
    },
    goApp() {
      window.location.href = this.url;
    },
    goWeb(){
      window.location.href = "https://h5.miglamp.com"
    },
    // 判断是否为移动设备
    isMobileDevice() {
      const userAgent = navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod|android|mobile|tablet|phone/.test(userAgent);
    },
    // 判断是否为 iOS 设备
    isIOS() {
      const userAgent = navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    },
    // 判断是否为 Android 设备
    isAndroid() {
      const userAgent = navigator.userAgent.toLowerCase();
      return /android/.test(userAgent);
    }
  }
}
</script>

<style scoped>
.router-link {
  color: #ffffff;
}

.main {
  height: auto;
  overflow: hidden;
}

.nav {
  height: auto;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  box-shadow: 0 10px 10px rgba(50, 50, 50, 0.07);
  position: relative;
}

.nav-logo {
  width: 150px;
  float: left;
}

.nav-logo img {
  width: 100%;
  display: block;
}

.reg-btn {
  float: right;
  border: 1px solid #3D3D46;
  background-color: #3D3D46;
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
}

.reg-btn:hover {
  border: 1px solid #3D3D46;
  background-color: #ffffff;
  color: #3D3D46;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.content {
  background-image: url("../assets/login_bg.jpg");
  background-repeat: repeat-x;
  min-height: 380px;
  background-size: auto 180px;
  position: relative;
  overflow: hidden;
}

.big_title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-top: 40px;
}

.small_title {
  max-width: 600px;
  text-align: center;
  margin: 30px auto 0;
  padding: 0 30px;
  color: #9395A9;
}

.download {
  width: 200px;
  margin: 50px auto 0;
}

.download img {
  width: 100%;
}

.iso_down_btn {
  width: 260px;
  background-image: linear-gradient(to right, #EA3324, #EB4766);
  margin: 30px auto 0;
  border-radius: 50px;
  text-align: center;
  font-size: 21px;
  font-weight: bold;
  color: #ffffff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iso_down_btn img {
  width: 24px;
  margin-right: 10px;
}

.iso_down_btn:hover {
  background-image: linear-gradient(to right, #EB4766, #EA3324);
  cursor: pointer;
}

.android_down_btn {
  width: 260px;
  background-image: linear-gradient(to right, #EA3324, #EB4766);
  margin: 30px auto 0;
  border-radius: 50px;
  text-align: center;
  font-size: 21px;
  font-weight: bold;
  color: #ffffff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.android_down_btn img {
  width: 24px;
  margin-right: 10px;
}

.android_down_btn:hover {
  background-image: linear-gradient(to right, #EB4766, #EA3324);
  cursor: pointer;
}

.web_browsing_btn {
  width: 260px;
  background-image: linear-gradient(to right, #000000, #303030);
  margin: 10px auto 0;
  border-radius: 50px;
  text-align: center;
  font-size: 21px;
  font-weight: bold;
  color: #ffffff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tips {
  margin-top: 10px;
  text-align: center;
  font-size: 16px;
  color: #9395A9;;
}

.pc {
  text-align: center;
}

.scan_tip {
  text-align: center;
  max-width: 400px;
  margin: 0 auto 30px;
  background-color: #EA3324;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 100px;
}

@media (max-width: 768px) {
  .big_title {
    padding: 0 30px;
  }
}

</style>
